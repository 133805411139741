import React, { type ChangeEventHandler } from "react";
import {
  FormGroup,
  TextArea,
  Collapse,
  Button,
  MenuItem,
  type MaybeElement,
  type IconName,
  MenuDivider,
} from "@blueprintjs/core";
import { Select, type ItemRenderer } from "@blueprintjs/select";
import type { Args, SelectEntries } from "@/types";
import ArrowSvg from "./icons/Arrow";
import { ArrowDown2 } from "iconsax-react";
import Fuse from "fuse.js";

const languageOptions: string[] = [
  "English",
  "Arabic",
  "Chinese",
  "Croatian",
  "Danish",
  "Dutch",
  "French",
  "German",
  "Greek",
  "Hebrew",
  "Hindi",
  "Indonesian",
  "Italian",
  "Korean",
  "Latin",
  "Norwegian",
  "Pashto (beta)",
  "Portuguese",
  "Russian",
  "Spanish",
  "Swahili (beta)",
  "Swedish",
  "Ukrainian",
  "Yiddish",
];

const curriculumStandards = [
  { label: "None", value: "" },
  { label: "Common Core State Standards (CCSS)", value: "common_core" },
  { label: "National Association for Gifted Children (NAGC)", value: "nagc" },
  { label: "New York", value: "nyc" },
  { label: "Florida", value: "fl" },
  { label: "Texas", value: "tx" },
];

const questionTypes: string[] = [
  "Multiple Choice",
  "Fill in the Blank",
  "True or False",
  "Short Answer",
  "Essay",
];

const gradeLevelOptions = [
  { label: "", value: "" },
  { label: "Kindergarten Level", value: "Kindergarten" },
  { label: "1st Grade Level", value: "1" },
  { label: "2nd Grade Level", value: "2" },
  { label: "3rd Grade Level", value: "3" },
  { label: "4th Grade Level", value: "4" },
  { label: "5th Grade Level", value: "5" },
  { label: "6th Grade Level", value: "6" },
  { label: "7th Grade Level", value: "7" },
  { label: "8th Grade Level", value: "8" },
  { label: "9th Grade Level", value: "9" },
  { label: "10th Grade Level", value: "10" },
  { label: "11th Grade Level", value: "11" },
  { label: "12th Grade Level", value: "12" },
  { label: "Higher Education", value: "university" },
];

const gradeLevelOptionsMap = Object.fromEntries(
  gradeLevelOptions.map((o) => [o.value, o.label])
);

const difficultyLevelOptions: string[] = [
  "Very Easy",
  "Easy",
  "Medium",
  "Hard",
  "Very Hard",
];

/*
const itemRenderer: ItemRenderer<string> = (item, { handleClick, modifiers }) => (
  <MenuItem
    key={item}
    onClick={handleClick}
    text={item}
  />
);
*/

export const LabelledInput = ({
  label,
  id,
  children,
}: {
  label: string;
  id: string;
  children: React.ReactNode;
}) => (
  <FormGroup label={label} labelFor={id} className="font-semibold text-white">
    {children}
  </FormGroup>
);

const LabelledTextarea = ({
  label,
  id,
  name,
  value,
  placeholder,
  onChange,
}: {
  label: string;
  id: string;
  name: string;
  value: string;
  placeholder?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
}) => (
  <LabelledInput label={label} id={id}>
    <TextArea
      id={id}
      name={name}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      fill
      className="w-full"
    />
  </LabelledInput>
);

export const LabelledSelect = <Entries extends SelectEntries>({
  label,
  id,
  name,
  entries,
  filterable = true,
  selectedValue,
  onItemSelect,
  icon,
  loading = false,
  fallbackText = "(None specified)",
}: {
  label: string;
  id: string;
  name: string;
  entries: Entries;
  filterable?: boolean;
  selectedValue: Entries[number]["value"];
  onItemSelect: (
    item: Entries[number],
    event?: React.SyntheticEvent<HTMLElement>
  ) => void;
  icon?: (value: Entries[number]["value"]) => IconName | MaybeElement;
  loading?: boolean;
  fallbackText?: string;
}) => {
  const fuse = React.useMemo(() => new Fuse(entries, { keys: ["label"] }), [entries]);

  return <LabelledInput label={label} id={id}>
    <Select<Entries[number]>
      inputProps={{ id, name }}
      items={entries}
      itemRenderer={({ value, label }, { handleClick }) => (
        <MenuItem
          key={value}
          onClick={handleClick}
          text={label || "(None specified)"}
        />
      )}
      // noResults={<MenuItem disabled text="No results." />}
      filterable={filterable}
      activeItem={entries.find((entry) => entry.value === selectedValue)}
      itemListPredicate={(query, items) =>
        query ? fuse.search(query).map((result) => result.item) : items
      }
      onItemSelect={onItemSelect}
      popoverProps={{ minimal: true, matchTargetWidth: true }}
      fill
    >
      <Button
        text={
          entries.find((entry) => entry.value === selectedValue)?.label ||
          fallbackText
        }
        // rightIcon="double-caret-vertical"
        // rightIcon="caret-down"
        // rightIcon="arrow-down"
        rightIcon={
          <ArrowDown2 variant="Linear" size={20} className="text-primary" />
        }
        loading={loading}
        className="inline-flex rounded-lg bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-none w-full"
        textClassName="min-w-24 flex-1"
        icon={icon?.(selectedValue)}
      />
    </Select>
  </LabelledInput>
};

export const LabelledSimpleSelect = <Entries extends Readonly<string[]>>({
  label,
  id,
  name,
  entries,
  filterable = true,
  selectedValue,
  onItemSelect,
  icon,
  loading = false,
}: {
  label: string;
  id: string;
  name: string;
  entries: Entries;
  filterable?: boolean;
  selectedValue: Entries[number];
  onItemSelect: (
    item: Entries[number],
    event?: React.SyntheticEvent<HTMLElement>
  ) => void;
  icon?: (value: Entries[number]) => IconName | MaybeElement;
  loading?: boolean;
}) => {
  const entryObjects = entries.map((value) => ({ value, label: value }));
  return (
    <LabelledSelect
      label={label}
      id={id}
      name={name}
      entries={entryObjects}
      filterable={filterable}
      selectedValue={selectedValue}
      onItemSelect={({ value }, e) => onItemSelect(value, e)}
      icon={icon}
      loading={loading}
    />
  );
};

export const LabelledGroupedSelect = <
  GroupValue extends Readonly<string>,
  Entries extends SelectEntries,
  Group extends { group: GroupValue; label: string; entries: Entries }
>({
  label,
  id,
  name,
  fallbackText = "(None specified)",
  loading = false,
  groups,
  selectedValue,
  onItemSelect,
  icon,
}: {
  label: string;
  id: string;
  name: string;
  fallbackText?: string;
  loading?: boolean;
  groups: Group[];
  selectedValue: Group["entries"][number]["value"];
  onItemSelect: (
    item: Group["entries"][number],
    event?: React.SyntheticEvent<HTMLElement>
  ) => void;
  icon?: IconName | MaybeElement;
}) => {
  const fuses = React.useMemo(
    () =>
      Object.fromEntries(
        groups.map((group) => [
          group.group,
          new Fuse(group.entries, {
            keys: ["label"],
          }),
        ])
      ) as Record<GroupValue, Fuse<Group["entries"][number]>>,
    [groups]
  );

  return (
    <LabelledInput label={label} id={id}>
      <Select<Group["entries"][number] & { group?: true }>
        inputProps={{ id, name }}
        items={groups.flatMap(({ group, label, entries }) => [
          { group: true, value: group, label } as const,
          ...entries,
        ])}
        itemRenderer={({ group, value, label }, { handleClick }) =>
          group ? (
            <MenuDivider key={value} title={label} />
          ) : (
            <MenuItem
              key={value}
              onClick={handleClick}
              text={label || "(None specified)"}
            />
          )
        }
        // noResults={<MenuItem disabled text="No results." />}
        filterable
        itemListPredicate={(query, items) =>
          query
            ? groups.flatMap(({ group, label }) => [
                { group: true, value: group, label } as const,
                ...fuses[group].search(query).map((result) => result.item),
              ])
            : items
        }
        activeItem={groups
          .flatMap(({ entries }) => entries)
          .find((entry) => entry.value === selectedValue)}
        onItemSelect={onItemSelect}
        popoverProps={{ minimal: true, matchTargetWidth: true }}
        fill
      >
        <Button
          text={
            groups
              .flatMap(({ entries }) => entries)
              .find((entry) => entry.value === selectedValue)?.label ||
            fallbackText
          }
          loading={loading}
          // rightIcon="double-caret-vertical"
          // rightIcon="caret-down"
          // rightIcon="arrow-down"
          rightIcon={
            <ArrowDown2 variant="Linear" size={20} className="text-primary" />
          }
          icon={icon}
          className="inline-flex rounded-lg bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-none w-full"
        />
      </Select>
    </LabelledInput>
  );
};

interface ArgSelectFormProps {
  state: Args;
  setState: React.Dispatch<(prevState: Args) => Args>;
}

function ArgSelectForm({ state, setState }: ArgSelectFormProps) {
  const [isComplexityOpen, setIsComplexityOpen] = React.useState(false);
  const [isIndividualizationOpen, setIsIndividualizationOpen] =
    React.useState(false);

  const handleSelectChange = (
    key: keyof ArgSelectFormProps["state"],
    value: string | undefined
  ) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <div className="flex max-w-sm basis-32 flex-col gap-4 mt-4">
      {/* <FormGroup label="Language" labelFor="language">
        <Select<string>
          items={["", ...languageOptions]}
          itemRenderer={(item, { handleClick }) => (
            <MenuItem
              key={item || "none"}
              onClick={handleClick}
              text={item || <>&nbsp;</>}
            />
          )}
          noResults={<MenuItem disabled text="No results." />}
          onItemSelect={(item) =>
            handleSelectChange("language", item || undefined)
          }
        >
          <Button
            text={state.language || <>&nbsp;</>}
            // rightIcon="double-caret-vertical"
            // rightIcon="caret-down"
            rightIcon={<ArrowSvg width={20} height={20} />}
            className="inline-flex justify-between rounded-lg bg-white px-3 py-2 text-sm font-semibold text-gray-900"
            textClassName="min-w-24"
            left="globe"
          />
        </Select>
      </FormGroup> */}

      <LabelledSimpleSelect
        label="Language"
        id="language"
        name="language"
        entries={["", ...languageOptions]}
        selectedValue={state.language || ""}
        onItemSelect={(value) => handleSelectChange("language", value)}
        icon={() => "globe"}
      />

      <Button
        onClick={() => setIsComplexityOpen((b) => !b)}
        // rightIcon="caret-down"
        rightIcon={
          <ArrowDown2 variant="Linear" size={20} className="text-primary" />
        }
      >
        Complexity
      </Button>
      <Collapse isOpen={isComplexityOpen}>
        {/* <FormGroup label="Difficulty Level" labelFor="difficultyLevel">
          <Select<string>
            items={["", ...difficultyLevelOptions]}
            itemRenderer={(item, { handleClick }) => (
              <MenuItem
                key={item || "none"}
                onClick={handleClick}
                text={item || <>&nbsp;</>}
              />
            )}
            noResults={<MenuItem disabled text="No results." />}
            onItemSelect={(item) =>
              handleSelectChange("difficultyLevel", item || undefined)
            }
          >
            <Button
              text={state.difficultyLevel || <>&nbsp;</>}
              rightIcon="double-caret-vertical"
              textClassName="min-w-24"
            />
          </Select>
        </FormGroup> */}

        <LabelledSimpleSelect
          label="Difficulty Level"
          id="difficultyLevel"
          name="difficultyLevel"
          entries={["", ...difficultyLevelOptions]}
          selectedValue={state.difficultyLevel || ""}
          onItemSelect={(value) => handleSelectChange("difficultyLevel", value)}
        />

        {/* <FormGroup label="Grade Level" labelFor="gradeLevel">
          <Select<(typeof gradeLevelOptions)[number]>
            items={gradeLevelOptions}
            itemRenderer={(item, { handleClick }) => (
              <MenuItem
                key={item.value || ""}
                onClick={handleClick}
                text={item.label || ""}
              />
            )}
            noResults={<MenuItem disabled text="No results." />}
            onItemSelect={(item) =>
              handleSelectChange("gradeLevel", item.value || undefined)
            }
          >
            <Button
              text={
                (state.gradeLevel &&
                  gradeLevelOptionsMap[state.gradeLevel]) || <>&nbsp;</>
              }
              rightIcon="double-caret-vertical"
              textClassName="min-w-24"
            />
          </Select>
        </FormGroup> */}

        <LabelledSelect
          label="Grade Level"
          id="gradeLevel"
          name="gradeLevel"
          entries={gradeLevelOptions}
          selectedValue={state.gradeLevel || ""}
          onItemSelect={(item) => handleSelectChange("gradeLevel", item.value)}
        />
      </Collapse>

      <Button
        onClick={() => setIsIndividualizationOpen((b) => !b)}
        // rightIcon="caret-down"
        rightIcon={
          <ArrowDown2 variant="Linear" size={20} className="text-primary" />
        }
      >
        Individualization
      </Button>
      <Collapse isOpen={isIndividualizationOpen}>
        {/* <FormGroup label="Objectives / Standards" labelFor="objectives">
          <TextArea
            id="objectives"
            fill
            value={state.objectives || ""}
            onChange={(e) => handleSelectChange("objectives", e.target.value)}
          />
        </FormGroup> */}
        <LabelledTextarea
          label="Objectives / Standards"
          id="objectives"
          name="objectives"
          value={state.objectives || ""}
          onChange={(e) => handleSelectChange("objectives", e.target.value)}
        />

        {/* <FormGroup label="Accommodations" labelFor="accommodations">
          <TextArea
            id="accommodations"
            fill
            value={state.accomodations || ""}
            onChange={(e) =>
              handleSelectChange("accommodations", e.target.value)
            }
          />
        </FormGroup> */}

        <LabelledTextarea
          label="Accommodations"
          id="accomodations"
          name="accomodations"
          value={state.accomodations || ""}
          onChange={(e) => handleSelectChange("accomodations", e.target.value)}
        />
      </Collapse>
    </div>
  );
}

export default ArgSelectForm;

import * as React from "react";
import useResizeObserver from "@react-hook/resize-observer";
import { nanoid } from "nanoid";

export const fileToBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });

const openPopup = (url: string, title: string) => {
  const dualScreenLeft = window.screenLeft ?? window.screenX;
  const dualScreenTop = window.screenTop ?? window.screenY;

  const width =
    window.innerWidth ?? document.documentElement.clientWidth ?? screen.width;

  const height =
    window.innerHeight ??
    document.documentElement.clientHeight ??
    screen.height;

  const systemZoom = width / window.screen.availWidth;

  const left = (width - 500) / 2 / systemZoom + dualScreenLeft;
  const top = (height - 550) / 2 / systemZoom + dualScreenTop;

  const newWindow = window.open(
    url,
    title,
    `width=${500 / systemZoom},height=${
      550 / systemZoom
    },top=${top},left=${left}`
  );

  try {
    newWindow?.focus();
  } catch (_) {}

  return new Promise<void>((resolve, reject) => {
    const timer = setInterval(() => {
      if (newWindow?.closed) {
        clearInterval(timer);
        resolve();
      }
    }, 250);
  });
};

export const signInWithGooglePopup = () => openPopup("/google-sign-in", "Sign In with Google");

export const zip = <A, B>(a: A[], b: B[]): [A, B][] => a.map((k, i) => [k, b[i]] as const);

export const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

// Retry using exponential backoff with jitter, up to 10 times
export const persistent = async <T>(fn: () => T) => {
  let failErr;
  for (let i = 0; i < 10; i++) {
    try {
      return await fn();
    } catch (err) {
      failErr = err;
      await wait(2 ** i * 100 + Math.random() * 100);
    }
  }
  console.error(failErr);
  throw new Error("Persistent retry limit exceeded");
};

export const useSize = (target: React.RefObject<Element>) => {
  const [size, setSize] = React.useState<DOMRect | undefined>();

  React.useLayoutEffect(() => {
    setSize(target.current?.getBoundingClientRect());
  }, [target]);

  useResizeObserver(target, (entry) => setSize(entry.contentRect));
  return size;
};

export type RecursiveWith<T> = T & { children?: RecursiveWith<T>[] };
export type RecursiveWithId = RecursiveWith<{ id: string }>;

export const ancestorGenerator = function* (...elements: RecursiveWithId[]) {
  const toSearch = elements.flatMap((element) => element.children || []);
  while (toSearch.length > 0) {
    const element = toSearch.pop()!;

    yield element;

    if (element.children) {
      toSearch.push(...element.children);
    }
  }
};

export const copyWithNewIds = (...elements: RecursiveWithId[]) => {
  const copy = JSON.parse(JSON.stringify(elements)) as RecursiveWithId[];

  for (const element of ancestorGenerator(...copy)) {
    element.id = nanoid(5);
  }

  return copy;
};

import * as React from "react";
import type { SVGProps } from "react";
const SvgAi = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    // viewBox="0 0 23 23"
    viewBox="3 3 19 19"
    fill="none"
    {...props}
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.34}
      d="m9.341 15.145-.726 2.543-.727-2.543a4.02 4.02 0 0 0-2.761-2.762l-2.543-.726 2.543-.727A4.02 4.02 0 0 0 7.888 8.17l.727-2.543.726 2.543a4.02 4.02 0 0 0 2.762 2.761l2.543.727-2.543.726a4.02 4.02 0 0 0-2.762 2.762M16.887 8.721l-.231.925-.231-.925a3.02 3.02 0 0 0-2.194-2.194l-.926-.231.926-.231a3.02 3.02 0 0 0 2.194-2.195l.231-.925.231.925a3.02 3.02 0 0 0 2.194 2.195l.926.23-.926.232a3.02 3.02 0 0 0-2.194 2.194M15.668 19.312l-.352 1.056-.353-1.056a2.01 2.01 0 0 0-1.271-1.272l-1.057-.352 1.057-.352a2.01 2.01 0 0 0 1.271-1.272l.353-1.057.352 1.057c.2.6.671 1.072 1.271 1.272l1.057.352-1.056.352c-.6.2-1.072.671-1.272 1.272"
    />
  </svg>
);
export default SvgAi;

import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { ErrorBoundary } from '@sentry/react';
import "./instrument";
import App from './App.tsx'
import './index.css'

import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/select/lib/css/blueprint-select.css";

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <ErrorBoundary showDialog>
      <App />
    </ErrorBoundary>
  </StrictMode>,
)

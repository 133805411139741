import { Crisp } from "crisp-sdk-web";

import { useSession } from "./auth/react";
import { useEffect } from "react";

type ObjectWithoutNullables<T extends Record<string, unknown>> = {
  [K in keyof T]: T[K] extends null | undefined ? never : T[K];
};

const objectWithoutNulls = <T extends Record<string, unknown>>(obj: T) =>
  Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(obj).filter(([_, value]) => value != null)
  ) as ObjectWithoutNullables<T>;

const CrispChat = () => {
  const session = useSession();
  useEffect(() => {
    Crisp.configure("8d269f57-bead-4331-be6e-cf199123234d");
    Crisp.setSafeMode(import.meta.env.PROD);
    Crisp.session.setSegments(["creator"], false);
  }, []);
  useEffect(() => {
    const { user } = session?.data ?? {};
    if (user) {
      if (user.id) {
        Crisp.setTokenId(user.id);
      }
      if (user.email) {
        Crisp.user.setEmail(user.email);
      }
      if (user.name) {
        Crisp.user.setNickname(user.name);
      }
      if (user.image) {
        Crisp.user.setAvatar(user.image);
      }
      Crisp.session.setData(objectWithoutNulls({ ...user }));
    }
  }, [session]);

  return null;
};

export default CrispChat;

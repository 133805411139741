"use client";

import { nanoid } from "nanoid";

const signedIn = async () => {
  return window?.project?.cloudEnabled;
};

const throwOnNotOk = async (res: Response) => {
  if (res.ok) {
    return res;
  } else {
    console.error(res);
    throw new Error("Request failed with status " + res.status);
  }
};

export async function listDesigns() {
  if (await signedIn()) {
    return fetch("/api/polotno/designs")
      .then(throwOnNotOk)
      .then((res) => res.json())
  } else {
    return [];
  }
}

export async function deleteDesign({ id }: { id: string }) {
  if (await signedIn()) {
    await fetch(`/api/polotno/design/${id}`, {
      method: "DELETE",
    }).then(throwOnNotOk);
  }
}

export async function loadById({ id }: { id: string }) {
  if (await signedIn()) {
    return fetch(`/api/polotno/design/${id}/load`)
      .then(throwOnNotOk)
      .then((res) => res.json())
  } else {
    return { storeJSON: undefined, name: "" };
  }
}

export async function saveDesign({
  storeJSON,
  preview,
  name,
  id,
}: {
  storeJSON: any;
  preview: File;
  name: string;
  id?: string;
}) {
  if (await signedIn()) {
    const formData = new FormData();

    formData.append("storeJSON", JSON.stringify(storeJSON));
    formData.append("preview", preview);
    formData.append("name", name);

    id ||= nanoid(10);

    return fetch(`/api/polotno/design/${id}/save`, {
      method: "POST",
      body: formData,
    })
      .then(throwOnNotOk)
      .then((res) => res.json())
  } else {
    return { id: nanoid(10), status: "saved" };
  }
}

const DEFAULT_IMAGE_SRC =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGUlEQVQYV2NkYGD4D8QEAeOoQnxhRP3gAQCpOgoBH462WwAAAABJRU5ErkJggg==";

export const getPreview = async ({ id }: { id: string }) => {
  if (await signedIn()) {
    return (
      fetch(`/api/polotno/design/${id}/preview`)
        .then(throwOnNotOk)
        .then((res) => res.json())
    ).then((data) => data.url);
  } else {
    return DEFAULT_IMAGE_SRC;
  }
};

export const listAssets = async () => {
  if (await signedIn()) {
    return fetch("/api/polotno/assets")
      .then(throwOnNotOk)
      .then((res) => res.json())
  } else {
    return [];
  }
};

export const uploadAsset = async ({
  file,
  preview,
}: {
  file: File;
  preview: Blob;
}) => {
  await throwIfNotSignedIn();
  const formData = new FormData();

  formData.append("file", file);
  formData.append("preview", preview);

  return fetch("/api/polotno/asset", {
    method: "POST",
    body: formData,
  })
    .then(throwOnNotOk)
    .then((res) => res.json())
};

export const deleteAsset = async ({ id }: { id: string }) => {
  if (await signedIn()) {
    await fetch(`/api/polotno/asset/${id}`, {
      method: "DELETE",
    }).then(throwOnNotOk);
  }
};

export const uploadDocument = async ({ file }: { file: File }) => {
  await throwIfNotSignedIn();
  const formData = new FormData();

  formData.append("file", file);
  formData.append("fileName", file.name);

  return fetch("/api/polotno/topic_doc", {
    method: "POST",
    body: formData,
  })
    .then(throwOnNotOk)
    .then((res) => res.json())
};

const throwIfNotSignedIn = async () => {
  if (!(await signedIn())) {
    window.project.openLoginDialog();
    throw new Error("Not signed in");
  }
};

export const parsePDF = async ({ file }: { file: File }) => 
  throwIfNotSignedIn()
    .then(() => fetch("/api/parse/pdf", {
      method: "POST",
      body: file,
  }))
    .then(throwOnNotOk)
    .then((res) => res.json());

export const getTpt = async ({ slug }: { slug: string }) =>
  throwIfNotSignedIn()
    .then(() => fetch(`/api/tpt/${slug}`))
    .then(throwOnNotOk)
    .then((res) => res.json())

export const postTpt = async ({
  slug,
  resources,
}: {
  slug: string;
  resources: ({
    downloadUrls: string[];
  })[];
}) =>
  throwIfNotSignedIn()
    .then(() => fetch(`/api/tpt/${slug}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(resources),
  }))
    .then(throwOnNotOk)
    .then(() => {});

export const parseSlides = async ({ id }: { id: string }) =>
  throwIfNotSignedIn()
    .then(() => fetch(`/api/parse/slides/${id}`, {
      method: "POST",
    }))
    .then(throwOnNotOk)
    .then((res) => res.json());

export const getLearningStandardJurisdictions = async () =>
  fetch("/api/learning_standards/jurisdictions")
    .then(throwOnNotOk)
    .then((res) => res.json())

export const getLearningStandardStandardSets = async ({
  jurisdictionId,
}: {
  jurisdictionId: string;
}) =>
  fetch(`/api/learning_standards/standard_sets/${jurisdictionId}`)
    .then(throwOnNotOk)
    .then((res) => res.json())

export const getLearningStandardStandards = async ({
  standardSetId,
}: {
  standardSetId: string;
}) =>
  fetch(`/api/learning_standards/standards/${standardSetId}`)
    .then(throwOnNotOk)
    .then((res) => res.json())

export const getCustomerSession = async () =>
  throwIfNotSignedIn()
    .then(() => fetch("/api/stripe/session/customer"))
    .then(throwOnNotOk)
    .then((res) => res.json())

export const getBillingPortalSession = async (type: BillingPortalSessionType = "billing_portal") =>
  throwIfNotSignedIn()
    .then(() => fetch(`/api/stripe/session/${type}`))
    .then(throwOnNotOk)
    .then((res) => res.json())

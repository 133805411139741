"use client";

import React from 'react';
import { observer } from 'mobx-react-lite';

import { Button, Menu, MenuItem, Popover, MenuDivider } from '@blueprintjs/core';
import { projectSignIn, useProject } from '../project';
import { useSession } from '../auth/react';
import { signOut } from '../auth/react';
import { getBillingPortalSession } from '../api';

export const UserMenu = observer(({ store }) => {
  const project = useProject();
  // const [user, setUser] = React.useState(null);
  const session = useSession();

  const user = session.data?.user;

  // React.useEffect(() => {
  //   if (project.cloudEnabled) {
  //     window.puter.auth.getUser().then((user) => {
  //       setUser(user);
  //     });
  //   }
  // }, [project.cloudEnabled]);
  return (
    <>
      <Popover
        content={
          <Menu style={{ width: '80px !important' }}>
            {project.cloudEnabled && (
              <div style={{ padding: '5px' }}>Logged in as {user?.name || user?.email}</div>
            )}
            {!project.cloudEnabled && (
              <MenuItem
                text="Login"
                icon="log-in"
                onClick={async () => {
                  projectSignIn(project, () => session.update());
                }}
              />
            )}
            {/* {project.cloudEnabled && (
              <MenuItem
                text="Subscription"
                icon={'thumbs-up'}
                onClick={() => {
                  // toggleSubModal(true);
                }}
              />
            )} */}
            {project.cloudEnabled && (
              <>
                {project.subscriptionActive ? (
                  <>
                    <MenuDivider title="Manage Subscription" />
                    {(
                      [
                        ["subscription_update", "Change Subscription"],
                        ["payment_method_update", "Update Payment Info"],
                      ] as const
                    ).map(([type, text]) => (
                      <MenuItem
                        key={type}
                        text={text}
                        onClick={async () => {
                          const { session: { url } } = await getBillingPortalSession(type);
                          window.open(url, '_blank');
                        }}
                      />
                    ))}
                      <MenuItem
                        text="Cancel Subscription"
                        onClick={() => project.openCancelSurvey()}
                      />
                  </>
                ) : (
                  <>
                    <MenuDivider />
                    <MenuItem text="Subscribe!" onClick={() => {
                      project.setPricingTableOpen(true);
                    }} />
                  </>
                )}
                <MenuDivider />
                <MenuItem
                  text="Logout"
                  icon="log-out"
                  onClick={() => {
                    signOut();
                    // window.puter.auth.signOut();
                    // logout({ returnTo: window.location.origin, localOnly: true });
                  }}
                />
              </>
            )}
          </Menu>
        }
        position="bottom"
      >
        <Button
          icon="user"
          minimal
          intent={project.cloudEnabled ? 'none' : 'warning'}
        ></Button>
      </Popover>
    </>
  );
});

import * as React from "react";
import type { SVGProps } from "react";
const SvgLocation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    fill="none"
    {...props}
  >
    <path
      stroke="#6271EB"
      strokeWidth={1.226}
      d="M9.805 11.669a2.55 2.55 0 1 0 0-5.099 2.55 2.55 0 0 0 0 5.099Z"
    />
    <path
      stroke="#6271EB"
      strokeWidth={1.226}
      d="M2.958 7.632C4.568.556 15.05.564 16.65 7.64c.94 4.15-1.642 7.664-3.905 9.837a4.243 4.243 0 0 1-5.891 0C4.6 15.304 2.018 11.782 2.958 7.632Z"
    />
  </svg>
);
export default SvgLocation;

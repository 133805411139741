import React from "react";
import { observer } from "mobx-react-lite";

import { SectionTab } from "polotno/side-panel";
import { InputGroup } from "@blueprintjs/core";

import { ImagesGrid } from "polotno/side-panel/images-grid";
import { LEGACY_TEMPLATES, LEGACY_TEMPLATES_CATEGORIES } from "../legacyTemplates";
import { useProject } from "../project";
import { BackwardItem } from "iconsax-react";

import Fuse from "fuse.js";
import { StoreType } from "polotno/model/store";
import { nanoid } from "nanoid";

const categoryTemplates = Object.fromEntries(
  LEGACY_TEMPLATES_CATEGORIES.map(
    ({ value }) => [
      value,
      value ? LEGACY_TEMPLATES.filter(
        (template) => template.categories.includes(value)
      ) : LEGACY_TEMPLATES
    ]
  )
) as Record<Category, Template[]>;

const fuses = Object.fromEntries(
  LEGACY_TEMPLATES_CATEGORIES.map(
    (category) => [category.value, new Fuse(
      categoryTemplates[category.value],
      {
        keys: ["title", "value", "description", "details"],
      }
    )]
  )
) as Record<Category, Fuse<Template>>;

type Template = (typeof LEGACY_TEMPLATES)[number];
type Category = (typeof LEGACY_TEMPLATES_CATEGORIES)[number]["value"];

export const TemplatesPanel = observer(({ store }: { store: StoreType }) => {
  const project = useProject();

  const isLoading = false;

  const [search, setSearch] = React.useState("");
  const [category, setCategory] = React.useState<Category>("")

  const filteredData = React.useMemo(() => {
    if (!search) return categoryTemplates[category];
    return fuses[category].search(search).map((result) => result.item);
  }, [category, search]);

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <InputGroup
        fill
        value={search}
        placeholder="Search..."
        leftIcon="search"
        onValueChange={(name) => {
          setSearch(name);
        }}
      />
      <div className="flex flex-row flex-wrap gap-2">
        {LEGACY_TEMPLATES_CATEGORIES.map(({ value, label }) => (
          <div key={value}>
            <label
              className={`cursor-pointer focus:ring-ring inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2${
                category === value ? " bg-primary text-white hover:bg-primary/80 border-transparent" : ""
              }`}
            >
              {label}
              <input
                type="radio"
                name="category"
                className="sr-only"
                value={value}
                checked={category === value}
                onChange={(e) => e.target.checked && setCategory(value)}
              />
            </label>
          </div>
        ))}
      </div>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <p>Loading...</p>
        </div>
      ) : filteredData.length === 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <p>No templates found</p>
        </div>
      ) : (
        <ImagesGrid
          shadowEnabled={false}
          images={filteredData}
          getPreview={(item: Template) =>
            `/static/images/templates/${item.value}.jpg`
          }
          getCredit={(item: Template) => item.title}
          isLoading={isLoading}
          onSelect={async (item: Template) => {
            project.runWithLoading(async () => {
              const json = await fetch(
                `/static/jsons/${item.value}.json`
              )
                .then((res) => res.json()) as (
                    Omit<ReturnType<typeof store.toJSON>, "pages"> & {
                      width: number;
                      height: number;
                      pages: {
                        id: string;
                        width: number | "auto";
                        height: number | "auto";
                        children: { id: string }[];
                        custom: {
                          [key: string]: any;
                        };
                      }[];
                    }
                  );
              // store.loadJSON(item.json);
              const { width, height } = json;
              const groupId = nanoid(5);
              project.addTempOverridablePages(json.pages.map((page, index) => ({
                ...page,
                width: !page.width || page.width === "auto" ? width : page.width,
                height: !page.height || page.height === "auto" ? height : page.height,
                custom: {
                  ...page.custom,
                  templatePageIndex: index,
                  groupId,
                },
              })));
              project.setRemixSideBarOpen(true);
            }, "Failed to load template, please try again later.");
          }}
          rowsNumber={2}
        />
      )}
    </div>
  );
});

export const LegacyTemplatesSection = {
  name: "legacy-templates",
  Tab: (props) => (
    <SectionTab name="Templates" {...props}>
      <BackwardItem variant="Linear" className="inline" />
    </SectionTab>
  ),
  Panel: TemplatesPanel,
};

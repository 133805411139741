import * as React from "react";
import type { SVGProps } from "react";
const SvgSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      stroke="#B7BABC"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M8.625 15.75a7.125 7.125 0 1 0 0-14.25 7.125 7.125 0 0 0 0 14.25M16.5 16.5 15 15"
    />
  </svg>
);
export default SvgSearch;

"use client";

import { observer } from 'mobx-react-lite';
import { Button } from '@blueprintjs/core';
import { projectSignIn, useProject } from './project';
import { Cloud } from '@blueprintjs/icons';
import { useSession } from './auth/react';

export const CloudWarning = observer(() => {
  const session = useSession();

  const project = useProject();
  if (project.cloudEnabled) {
    return null;
  }

  return (
    <div>
      <p style={{ color: '#fbb360' }}>
        Warning! Your data is not saved.
      </p>
      {/* <p>
        If you want to have bigger storage and enable cloud saving please sign
        in with puter.com.
      </p> */}
      <Button
        fill
        intent="success"
        onClick={() => {
          projectSignIn(project, () => session.update());
        }}
        icon={<Cloud />}
      >
        Enable cloud storage
      </Button>
    </div>
  );
});

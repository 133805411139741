const Z_INDEXES = [
  "cancelSurvey",
  "blueprint",
  "tour",
  "loading",
] as const;

const BLUEPRINT_LIST_INDEX = Z_INDEXES.indexOf("blueprint");

// Blueprint must be 20
export const Z_INDEX = (zIndex: typeof Z_INDEXES[number]) =>
  20 + Z_INDEXES.indexOf(zIndex) - BLUEPRINT_LIST_INDEX;

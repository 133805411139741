"use client";

const extensionId = import.meta.env.VITE_PUBLIC_EXTENSION_ID;

export const hasExtension = async () =>
  !!(await window?.chrome?.runtime
    ?.sendMessage?.(extensionId, {
      action: "doAugmentResources",
      data: [],
    })
    .catch(() => false));

export const augmentResources = async (resources: any[]) =>
  window.chrome.runtime
    .sendMessage(extensionId, {
      action: "doAugmentResources",
      data: resources.map(({ __typename, canonicalSlugs, ...rest }) => ({
        __typename,
        ...rest,
        ...(__typename === "BundleResource"
          ? {
              canonicalSlugs,
            }
          : { canonicalSlug: canonicalSlugs[0] }),
      }))
    })
    .then((res) =>
      res.resources.map(({ downloadUrl, downloadUrls, canonicalSlug, canonicalSlugs, ...rest }) => ({
        downloadUrls: downloadUrl ? [downloadUrl] : downloadUrls,
				canonicalSlugs: canonicalSlug ? [canonicalSlug] : canonicalSlugs,
        ...rest,
      }))
    );

import { nanoid } from "nanoid";

const FITB_PATTERN = /__+/gm;
const LINK_REGEX = /<link .*?><\/link>/gm;

const TEXT_STYLE_ATTRIBUTES = [
  "-webkit-text-stroke",
  "color",
  "font-family",
  "font-size",
  "font-style",
  "font-weight",
  "letter-spacing",
  "line-height",
  "text-align",
  "text-decoration",
  "text-stroke",
];

const TEXT_STYLE_INHERIT = Object.fromEntries(
  TEXT_STYLE_ATTRIBUTES.map((attr) => [attr, "inherit"])
);

const zip = <T, U>(a: ArrayLike<T>, b: ArrayLike<U>): [T, U][] => {
  return Array.from(a).map((value, index) => [value, b[index]]);
};

const skippableDescentGenerator = function* (
  element,
  dom,
  options = { descend: true }
) {
  const toSearch = [[element, dom]];
  while (toSearch.length > 0) {
    const [element, dom] = toSearch.pop();

    if (dom.children.length !== 1 && !excuseMultipleChildren(dom)) {
      console.warn("Element's dom has multiple children", dom);
      continue;
    }
    if (
      element.children &&
      dom.children[0].children.length !== element.children.length
    ) {
      console.warn(
        "Mismatch in elelement and dom children length",
        element,
        dom
      );
      continue;
    }

    yield { element, dom };

    if (options.descend) {
      for (const [child, domChild] of zip(
        element.children || [],
        dom.children[0].children
      )) {
        toSearch.push([child, domChild]);
      }
    } else {
      options.descend = true;
    }
  }
};

const polotnoWithDomFind = (element, dom, pred) => {
  for (const pair of skippableDescentGenerator(element, dom)) {
    const { element, dom } = pair;
    if (pred({ element, dom })) {
      return { element, dom };
    }
  }
};

const polotnoWithDomFindAll = (element, dom, pred, noRecurseOnTrue = false) => {
  const results = [];
  const options = { descend: true };
  for (const pair of skippableDescentGenerator(element, dom, options)) {
    const { element, dom } = pair;
    options.descend = true;
    if (pred({ element, dom })) {
      results.push({ element, dom });
      if (noRecurseOnTrue) {
        options.descend = false;
      }
    }
  }
  return results;
};

const polotnoGetInnerText = (element) => {
  const toSearch = [element];
  let text = "";
  while (toSearch.length > 0) {
    const current = toSearch.pop();
    if (current.type === "text") {
      if (text) {
        text += " ";
      }
      text += current.text;
    }
    if (current.children) {
      for (const child of current.children) {
        toSearch.push(child);
      }
    }
  }
  return text;
};

const excuseMultipleChildren = (dom) => dom.children[1].type === "input";

const mcqHandler = ({ element, dom, id, options, answerTypes }) => {
  const inputId = `${id}-val-${nanoid(5)}`;
  let inputParent = dom;
  let inputClass = "hidden";

  if (element.type === "text") {
    dom.type = "label";
    dom.props.for = inputId;
    dom.props.class = "box-checked";
  } else if (element.type === "group") {
    const markPair = polotnoWithDomFind(
      element,
      dom,
      ({ element }) => element.type === "figure"
    );

    const nonMarkPairs = polotnoWithDomFindAll(
      element,
      dom,
      ({ element }) =>
        element.type !== "group" && element.id !== markPair?.element?.id
    );

    if (markPair) {
      if (nonMarkPairs.length > 0) {
        inputParent = markPair.dom;
        inputClass = "overlay-check";
      } else {
        nonMarkPairs.push(markPair);
      }
    }

    for (const { dom } of nonMarkPairs) {
      dom.type = "label";
      dom.props.for = inputId;
      if (!markPair) {
        dom.props.class = "box-checked";
      }
    }
  } else {
    console.warn("Unknown element type for answer_mcq", element.type, element);
    return;
  }

  const text = polotnoGetInnerText(element);

  const input = {
    type: "input",
    props: {
      id: inputId,
      class: inputClass,
      type: "",
      name: id,
      value: text,
    },
    children: [],
  };

  if (options?.multiple) {
    answerTypes.add("mcq_multiple");
    input.props.type = "checkbox";
    inputParent.children.push(input);
  } else {
    answerTypes.add("mcq_single");
    input.props.type = "radio";
    inputParent.children.push(input);
  }
};
const prepReplaceBlockDom = ({ element, dom }) => {
  let mutateDom;
  if (element.type === "text") {
    mutateDom = dom;
  } else if (element.type === "group") {
    if (dom.children.length !== 1) {
      console.warn("replace block group has multiple direct children", dom);
      return;
    }
    if (dom.children[0].children.length === 0) {
      console.warn("replace block group has no children", dom);
      return;
    }
    mutateDom = dom.children[0].children[0];
    const bboxes = dom.children[0].children
      .map(
        ({
          props: {
            style: { left, top, width, height },
          },
        }) => [left, top, width, height]
      )
      .map((vals) =>
        vals.map((val) =>
          Number(typeof val === "string" ? val.replace("px", "") : val)
        )
      )
      .map(([left, top, width, height]) => ({
        x0: left,
        y0: top,
        x1: left + width,
        y1: top + height,
      }));

    const bbox = {
      x0: Math.min(...bboxes.map(({ x0 }) => x0)),
      y0: Math.min(...bboxes.map(({ y0 }) => y0)),
      x1: Math.max(...bboxes.map(({ x1 }) => x1)),
      y1: Math.max(...bboxes.map(({ y1 }) => y1)),
    };

    const position = {
      left: `${bbox.x0}px`,
      top: `${bbox.y0}px`,
      width: `${bbox.x1 - bbox.x0}px`,
      height: `${bbox.y1 - bbox.y0}px`,
    };

    Object.assign(mutateDom.props.style, position);

    dom.children[0].children.splice(1);
  } else {
    console.warn(
      "replace block is not a text or group, is type",
      element.type,
      element
    );
    return;
  }

  Object.assign(mutateDom.props.style, TEXT_STYLE_INHERIT);
  mutateDom.children = [];

  return mutateDom;
};

const textHandler = ({
  textLong,
  element,
  dom,
  id,
  answerTypes,
  textAnswers,
}) => {
  const mutateDom = prepReplaceBlockDom({ element, dom });
  if (!mutateDom) {
    return;
  }
  if (textLong) {
    answerTypes.add("long");

    Object.assign(mutateDom, {
      type: "textarea",
      props: {
        ...mutateDom.props,
        name: id,
      },
    });
  } else {
    answerTypes.add("short");

    Object.assign(mutateDom, {
      type: "input",
      props: {
        ...mutateDom.props,
        type: "text",
        name: id,
      },
    });
  }

  textAnswers.push([element, dom]);
};

const satisfiesWeirdTextDomChildrenPattern = (dom) =>
  dom.children &&
  dom.children.length === 1 &&
  dom.children[0].children.length === 2 &&
  dom.children[0].children[0].children.length === 0 &&
  dom.children[0].children[1].children.length === 1 &&
  typeof dom.children[0].children[1].children[0] === "string";

const fitbAfterHandler = ({ questionTextPairs, fitbAnswers, id }) => {
  const match = questionTextPairs.find(([element]) => {
    return FITB_PATTERN.test(element.text);
  });
  if (!match) {
    console.warn("No fitb match found", questionTextPairs);
    return;
  }
  const [element, dom] = match;
  if (!satisfiesWeirdTextDomChildrenPattern(dom)) {
    console.warn("Invalid fitb question_text", dom, element);
    return;
  }
  const fitbMatch = FITB_PATTERN.exec(element.text)?.[0];
  const [before, after] = element.text.split(FITB_PATTERN);
  const middle = {
    type: "span",
    props: {
      class: "replace",
    },
    children: [
      {
        type: "select",
        props: {
          name: id,
        },
        children: [
          {
            type: "option",
            props: {
              hidden: true,
              disabled: true,
              selected: true,
              value: "",
            },
            children: [],
          },
          ...fitbAnswers.map((text) => ({
            type: "option",
            props: {},
            children: [text],
          })),
        ],
      },
      {
        type: "span",
        props: {
          class: "replaced",
        },
        children: [fitbMatch],
      },
    ],
  };
  dom.children[0].children[1].children = [before, middle, after];
};

const textAfterHandler = ({ questionTextPairs, textAnswers }) => {
  const [_questionTextElement, questionTextDom] = questionTextPairs[0];
  if (!satisfiesWeirdTextDomChildrenPattern(questionTextDom)) {
    console.warn("Invalid question text dom", questionTextDom);
    return;
  }
  const {
    props: { style },
  } = questionTextDom.children[0].children[1];
  const textStyle = TEXT_STYLE_ATTRIBUTES.reduce((acc, attr) => {
    acc[attr] = style[attr];
    return acc;
  }, {});
  for (const [_element, dom] of textAnswers) {
    Object.assign(dom.props.style, textStyle);
  }
};

const elementHookCreator =
  (idMap) =>
  ({ element, dom }) => {
    if (element.custom?.type === "question_container") {
      const { id } = element;
      dom.props.id = id;

      const answerTypes = new Set();
      let questionText = "";

      const options = element.custom?.options;

      const questionTextPairs = [];
      const fitbAnswers = [];
      const textAnswers = [];

      const descentOptions = { descend: true };

      for (const pair of skippableDescentGenerator(
        element,
        dom,
        descentOptions
      )) {
        const { element, dom } = pair;

        descentOptions.descend = false;

        const type = element.custom?.type;

        if (type === "question_text") {
          questionText += element.text;
          questionTextPairs.push([element, dom]);
        } else if (type === "answer_mcq") {
          mcqHandler({ element, dom, id, options, answerTypes });
        } else if (type === "answer_fitb") {
          answerTypes.add("fitb");
          const text = polotnoGetInnerText(element);
          fitbAnswers.push(text);
        } else if (type === "answer_short") {
          textHandler({
            textLong: false,
            element,
            dom,
            id,
            answerTypes,
            textAnswers,
          });
        } else if (type === "answer_long") {
          textHandler({
            textLong: true,
            element,
            dom,
            id,
            answerTypes,
            textAnswers,
          });
        } else {
          descentOptions.descend = true;
        }
      }

      if (answerTypes.has("fitb")) {
        fitbAfterHandler({ questionTextPairs, fitbAnswers, id });
      }

      if (textAnswers.length > 0 && questionTextPairs.length > 0) {
        textAfterHandler({ questionTextPairs, textAnswers });
      }

      const name = element.name || "[unnamed question]";
      idMap[id] = {
        name,
        answerTypes: Array.from(answerTypes),
        questionText,
      };
      return dom;
    }
  };

const htmlOutputProcessorCreator = (idMap) => (html) => {
  const links = Array.from(html.matchAll(LINK_REGEX)).map(([link]) => link);
  const insertableHTML = html.replace(LINK_REGEX, "");
  return `
<html>
<head>
${links.join("\n")}
<style>
.hidden {
  display: none;
}

label.box-checked:has(input[type="radio"]:checked),
label.box-checked:has(input[type="checkbox"]:checked) {
  outline: black solid 1px;
}

.overlay-check:checked::before {
  width: 50%;
  height: 50%;
  content: "";
  background-color: white;
  top: 25%;
  left: 25%;
  position: absolute;
}

.overlay-check[type="radio"]:checked::before {
  border-radius: 100%;
}

.overlay-check {
  position: absolute;
  inset: 0;
  padding: 0;
  margin: 0;
  appearance: none;
}

span.replace {
  position: relative;
  display: inline-flex;
  vertical-align: baseline;
  height: fit-content;
  flex-direction: column;
}

span.replace .replaced {
  display: inline-block;
  visibility: hidden;
  height: 0;
}
</style>
</head>
<body>
<form id="form" classname="design">
${insertableHTML}
<input type="submit" value="Submit">
</form>
<script>
const idMap = ${JSON.stringify(idMap)};
document.getElementById("form").addEventListener("submit", (e) => {
  e.preventDefault();
  alert(Array.from((new FormData(e.target)).entries()).map(([key, val]) => \`\${idMap?.[key]?.questionText || "[unnamed question]"}: \${val}\`).join("\\n"))
});
</script>
</body>
</html>
  `.trim();
};

export const openInteractiveExport = (store) => {
  const idMap = {};

  return store
    .toHTML({
      elementHook: elementHookCreator(idMap),
    })
    .then(htmlOutputProcessorCreator(idMap))
    .then((html) =>
      URL.createObjectURL(new Blob([html], { type: "text/html" }))
    )
    .then((url) => {
      window.open(url, "_blank");
    });
};

"use client";

import React from "react";
import { observer } from "mobx-react-lite";
import {
  NavbarDivider,
  Popover,
} from "@blueprintjs/core";

import { MdcCloudAlert, MdcCloudCheck, MdcCloudSync } from "@meronex/icons/mdc";

import { useProject } from "../project";

import { DownloadButton } from "./download-button";
import { UserMenu } from "./user-menu";
import { CloudWarning } from "../cloud-warning";
import Remix from "../components/Remix";
import { StoreType } from "polotno/model/store";

const Status = observer(() => {
  const project = useProject();

  const Icon = !project.cloudEnabled
    ? MdcCloudAlert
    : project.status === "saved"
    ? MdcCloudCheck
    : MdcCloudSync;
  return (
    <Popover
      content={
        <div style={{ padding: "10px", maxWidth: "300px" }}>
          {!project.cloudEnabled && <CloudWarning />}
          {project.cloudEnabled && project.status === "saved" && (
            <>You data is saved in the cloud.</>
          )}
          {project.cloudEnabled &&
            (project.status === "saving" || project.status === "has-changes") &&
            "Saving..."}
        </div>
      }
			position="bottom"
      interactionKind="hover"
    >
      <div style={{ padding: "0 5px" }}>
        <Icon className="bp5-icon" style={{ fontSize: "25px", opacity: 0.8 }} />
      </div>
    </Popover>
  );
});

export const ActionControls = ({ store }: { store: StoreType }) => {
  const project = useProject();

  return (
    <>
      <Status />
      <NavbarDivider />
      <UserMenu store={store} project={project} />
      <NavbarDivider />
      <DownloadButton store={store} />
      <NavbarDivider />
      <Remix />
    </>
  );
};
